import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Bar = styled.aside`
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
  width: 15%;
  z-index: 1;
`

const NavBar = styled.nav`
  align-items: first baseline;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const NavLink = styled(props => <Link {...props} />)`
  background-color: rgba(255, 255, 255, 0.2);
  color: black;
  font-size: 1.5rem;
  margin: 1rem 0;
  padding: 0.5rem;
`

const Title = styled.h1`
  background-color: rgba(255, 255, 255, 0.2);
  color: black;
  display: inline-block;
  padding: 0.5rem;
  text-transform: uppercase;
`

const Sidebar = () => (
  <Bar>
    <Link to="/">
      <Title>Bordar BA <span role="img" aria-label="sparkle">✨</span></Title>
    </Link>
    <NavBar>
      <NavLink to="/about">Sobre el proyecto</NavLink>
      <NavLink to="/events">Eventos</NavLink>
      <NavLink to="/blog">Blog</NavLink>
      <NavLink to="/contact">Contacto</NavLink>
    </NavBar>
  </Bar>
)

export default Sidebar