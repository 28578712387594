import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Sidebar from './sidebar'
import './layout.css'

const Main = styled.main`
  padding: 4rem 2rem;
  height: 100vh;
  width: 85%;
  z-index: 1;
`

const Wrapper = styled.div`
  display: flex;
`

const ImageWrapper = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      landingImage: file(relativePath: { eq: "img-landing.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <Sidebar />
      <ImageWrapper>
        <Img fluid={data.landingImage.childImageSharp.fluid} />
      </ImageWrapper>
      <Main>
        { children }
      </Main>
    </Wrapper>
  )
}

export default Layout